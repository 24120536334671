<template>
  <div id="app" class="font-nunito">
    <MainWrapper />
  </div>
</template>
<script>
import MainWrapper from "./components/MainWrapper.vue";
export default {
  name: "App",
  components: {
    MainWrapper
  },
}
</script>


